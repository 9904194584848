<template>
<div class="check_tag_item">
  <span @click="handleItemClick" :class="{ 'cursor-pointer':true,'is_checked':isChecked}">{{label}}</span>
</div>
</template>
<script>
export default {
  name: 'CHECK_TAG_ITEM',
  props: {
    label: String,
    value: String || Number,
  },
  data () {
    return {
      isChecked: false,
    };
  },
  methods: {
    handleItemClick () {
      let parent = this.$parent;
      if (!this.isChecked) {
        this.isChecked = !this.isChecked;
        if (parent && parent.$options.componentName === 'check_tag') {
          parent.$emit('handleChange', this.isChecked ? this.value : '');
        }
      }
    },
  },
  created () {
    this.isChecked = this.$parent.value === this.value;
  },
  watch: {
    '$parent.value' () {
      this.isChecked = this.$parent.value === this.value;
    },
  },
};
</script>
<style lang="scss" scoped>
.check_tag_item {
  margin-right: 12px;
  line-height: 32px;

  >span {
    padding: 4px 8px;
    border:1px solid #efefef;
    line-height: 20px;
    vertical-align: middle;
    border-radius: 4px;
    &.is_checked{
      background: $primary-color;
      border:1px solid $primary-color;
      color: white;
    }
  }

}
</style>
