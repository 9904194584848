import store from '@/store';

const hasPermission = {
  inserted: (el, binding, vnode) => {
    filterGlobalPermission(el, binding, vnode);
  },
};

/**
 * 全局权限控制
 */
export const filterGlobalPermission = (el, binding) => {
  let permissionMap = store.state.Auth.actionPermissionMap;
  if (!permissionMap || !permissionMap[binding.value]) {
    el.parentNode.removeChild(el);
  }
};
export default hasPermission;
