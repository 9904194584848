import actions from './action';
import getters from './getters';
import state from './state';
import mutations from './mutations';
export default {
  getters: getters,
  actions: actions,
  state: state,
  mutations: mutations,
};
