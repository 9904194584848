import server from '../request';

// 新增销售合同
export const newSaleContract = (data) => server.post('/xiaozi-xmb/wireless/saas/customer/contact/add', data);

// 更新销采合同
export const updateContract = (data) => server.post('/xiaozi-xmb/wireless/saas/customer/contact/update', data);

// 销售合同分页
export const saleContractList = (data) => server.post('/xiaozi-xmb/wireless/saas/customer/contact/sale/page', {
  ...data,
  orderBy: [{
    orderByProperty: 'lastUpdateTime',
    orderByType: 'DESC',
  }],
});

// 采购合同分页
export const purchaseContractList = (data) => server.post('/xiaozi-xmb/wireless/saas/customer/contact/purchase/page', {
  ...data,
  orderBy: [{
    orderByProperty: 'lastUpdateTime',
    orderByType: 'DESC',
  }],
});

// 删除销采合同
export const deleteSaleAndPurchContract = (data) => server.post('/xiaozi-xmb/wireless/saas/customer/contact/deleteSaleAndPurchContract', data);

// 导出采购合同
export const exportPurchContract = (data) => server.post('/xiaozi-xmb/wireless/saas/customer/contact/exportPurchaseContract', data, {
  responseType: 'blob',
});

// 导出采购合同
export const exportSaleContract = (data) => server.post('/xiaozi-xmb/wireless/saas/customer/contact/exportSaleContract', data, {
  responseType: 'blob',
});

// 获取销售合同详情
export const saleContractDetail = (data) => server.post('/xiaozi-xmb/wireless/saas/customer/contact/sale/detail', data);

// 获取采购合同详情
export const purchaseContractDetail = (data) => server.post('/xiaozi-xmb/wireless/saas/customer/contact/purchase/detail', data);

// 数据字典查询
export const getOptionListMap = (data) => server.post('/xiaozi-xmb/wireless/saas/customer/contact/getOptionListMap', data);

// 获取设备列表
export const getDevices = (data) => server.post('/xiaozi-xmb/wireless/saas/customer/contact/getDevicePageList', data);

// 获取客户列表
export const getCustomer = (data) => server.post('/xiaozi-xmb/wireless/saas/customer/page', data);

// 小紫获取客户列表
export const xiaoziGetCustomer = (data) => server.post('/xiaozi-xmb/wireless/saas/customer/xzErp/customer/pageList', data);

// 查询供应商及其银行账号信息查询供应商及其银行账号信息
export const getSupplier = (data) => server.post('xiaozi-xmb/wireless/saas/customer/contact/supplier/info', data);

// 企业详情
export const tenantDetail = (data) => server.post('xiaozi-xmo/wireless/miniapp/tenant/detail', data);

// 销售订单分页
export const saleOrderList = (data) => server.post('/xiaozi-xmb/wireless/saas/customer/contact/order/sale/page', data);

// 采购订单分页
export const purchaseOrderList = (data) => server.post('/xiaozi-xmb/wireless/saas/customer/contact/order/purchase/page', data);

// 获取销售订单详情
export const saleOrderDetail = (data) => server.post('/xiaozi-xmb/wireless/saas/customer/contact/order/sale/detail', data);

// 获取采购订单详情
export const purchaseOrderDetail = (data) => server.post('/xiaozi-xmb/wireless/saas/customer/contact/order/purchase/detail', data);

// 销售类日志
export const saleLogList = (data) => server.post('/xiaozi-xmb/wireless/saas/customer/contact/saleLog/pageList', data);

// 采购类日志
export const purchaseLogList = (data) => server.post('/xiaozi-xmb/wireless/saas/customer/contact/purchaseLog/pageList', data);

// 审批分页列表
export const auditPageList = (data) => server.post('/xiaozi-xmb/wireless/saas/customer/contact/queryAuditPageList', data);

// 联系人地址分页列表
export const contactPersonAddressList = (data) => server.post('/xiaozi-xmb/wireless/saas/contactPerson/getContactPersonAddressPage', data);

// 小紫直营时地址分页列表
export const xiaoziContactPersonAddressList = (data) => server.post('/xiaozi-xmb/wireless/saas/customer/xzErp/customer/receiverGoodsAddress/pageList', data);

// 分页查询销售交易流水集合(实收信息)
export const saleCapitalFlow = (data) => server.post('/xiaozi-xmb/wireless/saas/customer/contact/capitalFlow/pageList', data);

// 查询合同付款信息(采购合同实收信息)
export const queryPurchPaymentInfo = (data) => server.post('/xiaozi-xmb/wireless/saas/customer/contact/queryPaymentInfo', data);

// 费率
export const getRate = (data) => server.post('/xiaozi-xmb/wireless/saas/customer/contact/getRate', data);

// 根据商品名称和二级服务分类查询一个sku信息
export const getOneSkuByNameAndSecondServiceCategoryName = (data) => server.post('/xiaozi-xmb/wireless/saas/customer/contact/getOneSkuByNameAndSecondServiceCategoryName', data);

// 新增销采订单
export const addSaleAndPurchOrder = (data) => server.post('/xiaozi-xmb/wireless/saas/customer/contact/addSaleAndPurchOrder', data);

// 更新销采订单
export const updateSaleAndPurchOrder = (data) => server.post('/xiaozi-xmb/wireless/saas/customer/contact/updateSaleAndPurchOrder', data);

// 取消合同
export const cancelSaleContract = (data) => server.post('/xiaozi-xmb/wireless/saas/customer/contact/cancel', data);

// 完结
export const finishSaleAndPurchContract = (data) => server.post('/xiaozi-xmb/wireless/saas/customer/contact/finishSaleAndPurchContract', data);

// 取消订单
export const cancelOrder = (data) => server.post('/xiaozi-xmb/wireless/saas/customer/contact/order/cancel', data);

// 开票信息
export const invoiceList = (data) => server.post('/xiaozi-xmb/wireless/saas/customer/contact/invoice/list', data);

// 根据订单id查询订单付款信息(采购订单实收信息)
export const purchCapitalFlow = (data) => server.post('/xiaozi-xmb/wireless/saas/customer/contact/viewOrderPayByOrderId', data);

// 删除销采订单
export const deleteSaleAndPurchOrder = (data) => server.post('/xiaozi-xmb/wireless/saas/customer/contact/deleteSaleAndPurchOrder', data);

// 取消销售合同（完结按钮之后用）
export const disableContract = (data) => server.post('/xiaozi-xmb/wireless/saas/customer/contact/disable', data);

// 企业基本信息
export const tenantBaseInfo = (data) => server.post('/xiaozi-xmo/wireless/miniapp/tenant/information/baseinfo', data);

// 收款按钮弹出框信息
export const receiptMoneyInfo = (data) => server.post('/xiaozi-xmb/wireless/saas/customer/contact/receipt/money/info', data);

// 收款单列表信息
export const contactClaimList = (data) => server.post('/xiaozi-xmb/wireless/saas/customer/contact/findClaimList', data);

// 收款
export const saleOrderReceive = (data) => server.post('/xiaozi-xmb/wireless/saas/customer/contact/receipt/money', data);

// 销售合同操作日志新增
export const addSaleLog = (data) => server.post('/xiaozi-xmb/wireless/saas/customer/contact/saleLog/add', data);

// 采购合同操作日志新增
export const addPurchaseLog = (data) => server.post('/xiaozi-xmb/wireless/saas/customer/contact/purchaseLog/add', data);

// 消息提醒新增
export const addMessageRemind = (data) => server.post('/xiaozi-xmb/wireless/saas/messageRemind/add', data);

// 字段规则列表
export const getFiledRuleLists = (data) => server.post('/xiaozi-xmb/wireless/saas/customer/filedRule/list', data);

// 新增字段规则
export const addFiledRule = (data) => server.post('/xiaozi-xmb/wireless/saas/customer/filedRule/add', data);
