<script>
export default {
  name: 'query_form',
  props: {
    // 默认折叠收起
    defaultCollapsed: {
      type: Boolean,
      default: true,
    },
    // 一个元素默认占位col span
    span: {
      type: Number,
      default: 6,
      validator: function (value) {
        return 24 % value === 0;
      },
    },
    // labelWidth
    labelWidth: {
      type: [String, Number],
    },
    // 校验规则
    rules: {
      type: Object,
    },
    // 模型
    model: {
      type: Object,
    },
    // label位置
    labelPosition: {
      type: String,
      default: 'right',
    },
    // 渲染查询重置按钮组
    isQuery: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // 保持几行展开
    keepOpenedRow: {
      type: Number,
      default: 1,
    },
  },
  data () {
    return {
      isCollapse: this.defaultCollapsed,
    };
  },
  methods: {
    validate () {
      this.$refs.queryForm.validate(...arguments);
    },
    resetFields () {
      this.$refs.queryForm.resetFields(...arguments);
    },
    clearValidate () {
      this.$refs.queryForm.clearValidate(...arguments);
    },
    validateField () {
      this.$refs.queryForm.validateField(...arguments);
    },
  },
  render: function (h) {
    if (!this.$slots.default) {
      return;
    }

    let children = this.$slots.default
      .filter(t => t.tag)
      .map(t => ({ vNode: t, colSpan: t.data?.attrs?.span || this.span }));
    // 每行列数
    let colCountPerRow = 24 / this.span;
    // 计算需展示组件的 span值total值
    let items = [];
    // 总共需要展示的col数量
    let totalDisplaySpan = 0;
    // 总共col数量，包含隐藏的col
    let totalSpan = 0;
    let defaultOpenedRow = children.filter(t => t.colSpan === 24).length;
    defaultOpenedRow = defaultOpenedRow > 0 ? defaultOpenedRow + 1 : this.keepOpenedRow;
    let defaultOpenedTotalSpan
      = defaultOpenedRow === 1
        ? (colCountPerRow - 1) * this.span
        : (defaultOpenedRow - 1) * 24 + (colCountPerRow - 1) * this.span;
    if (this.isCollapse) {
      let temp = defaultOpenedTotalSpan;
      for (let index = 0; index < children.length; index++) {
        const child = children[index];
        totalSpan += child.colSpan;
        temp = temp - child.colSpan;
        if (temp >= 0) {
          items.push(
            h(
              'el-col',
              {
                props: {
                  span: child.colSpan,
                },
              },
              [child.vNode],
            ),
          );
          totalDisplaySpan += child.colSpan;
        } else {
          break;
        }
      }
    } else {
      children.forEach(t => {
        items.push(
          h(
            'el-col',
            {
              props: {
                span: t.colSpan,
              },
            },
            [t.vNode],
          ),
        );
        totalSpan += t.colSpan;
        totalDisplaySpan += t.colSpan;
      });
    }

    if (this.isQuery) {
      let actionItems = this.$slots.action
        ? [this.$slots.action]
        : [
          h(
            'el-button',
            {
              props: {
                type: 'primary',
              },
              on: {
                click: () => {
                  this.$emit('search');
                },
              },
            },
            '查询',
          ),
          h(
            'el-button',
            {
              on: {
                click: () => {
                  this.$emit('reset');
                },
              },
            },
            '重置',
          ),
        ];

      if (children.length > 3 || totalSpan > totalDisplaySpan || totalDisplaySpan > defaultOpenedTotalSpan) {
        actionItems.push(
          h(
            'el-button',
            {
              props: {
                type: 'text',
                icon: !this.isCollapse ? 'el-icon-arrow-up' : 'el-icon-arrow-down',
              },
              on: {
                click: () => {
                  this.isCollapse = !this.isCollapse;
                  this.$emit('toogle');
                },
              },
            },
            this.isCollapse ? '展开' : '收起',
          ),
        );
      }
      // 计算重置按钮位置
      // let actionSpan = (items.length % colCountPerRow === 0) ? 24 : this.span * (colCountPerRow - (items.length % colCountPerRow));
      let actionSpan = totalDisplaySpan % 24 === 0 ? 24 : 24 - (totalDisplaySpan % 24);
      items.push(
        h(
          'el-col',
          {
            props: {
              span: actionSpan,
            },
            class: 'text-align-right',
          },
          [
            h(
              'el-form-item',
              {
                props: {
                  labelWidth: '0px',
                },
              },
              [...actionItems],
            ),
          ],
        ),
      );
    }
    return h(
      'el-form',
      {
        props: {
          labelWidth: this.labelWidth,
          rules: this.rules,
          model: this.model,
          labelPosition: this.labelPosition,
          disabled: this.disabled,
        },
        class: 'query_form',
        ref: 'queryForm',
      },
      [h('el-row', { style: { display: 'flex', flexWrap: 'wrap' } }, items)],
    );
  },
};
</script>
<style lang="scss" scoped>
.query_form {
  /deep/.el-form-item {
    margin-bottom: 12px!important;
  }
}
</style>
