import server from '../request';

export default {
  login ({ account, code, type, entryType, loginSource }) {
    return server.post('/xiaozi-xmo/wireless/saas/employee/login', {
      account, code, type, entryType, loginSource,
    }, {
      stopDefaultMessage: true,
      stopDefaultLoading: true,
    });
  },
  logout () {
    return server.post('/xiaozi-xmo/wireless/saas/employee/signOut', {});
  },
  // 生成带参数的二维码
  getWechatQrcode () {
    return server.get('/xiaozi-xmo/wireless/saas/employee/wechat/login/qrcode');
  },
  // 校验微信扫码登录状态
  checkWechatLogin (data) {
    return server.post('/xiaozi-xmo/wireless/saas/employee/wechat/check/login', data, {
      stopDefaultMessage: true,
      stopDefaultLoading: true,
    });
  },
  // 微信登录绑定手机号并登录
  bindPhone (data) {
    return server.post('/xiaozi-xmo/wireless/saas/employee/wechat/login/bind/phone', data);
  },
  // 获取图形验证码
  getCaptcha (data) {
    return server.post('/xiaozi-xmo/captcha/get', data);
  },
  checkCaptcha (data) {
    return server.post('/xiaozi-xmo/captcha/check', data);
  },

  sendValidCode ({ captchaVO, phone, captchaType }) {
    return server.post('/xiaozi-xmo/wireless/saas/employee/sendCaptcha', {captchaVO, phone, captchaType});
  },

  register (param) {
    return server.post('/xiaozi-xmo/wireless/saas/employee/register', param);
  },

  switchCompany (tenantId) {
    return server.post('/xiaozi-xmo/wireless/saas/employee/loginCompany', {tenantId});
  },
  // 获取菜单列表
  getTreeMenuList () {
    return server.post('/xiaozi-xmo/wireless/saas/menu/productMenuTree');
  },

  // 获取菜单列表
  getTreeMenuListWithAuth () {
    return server.post('/xiaozi-xmo/wireless/saas/menu/employeeMenuList');
  },
  // 获取菜单列表
  getTreeMenuTreeWithAuth () {
    return server.post('/xiaozi-xmo/wireless/saas/menu/employeeMenuTree');
  },
  // 获取所属维修商列表
  ownerList (data) {
    return server.post('/xiaozi-xmo/wireless/miniapp/user/ownerList', data);
  },
};
